<template>
    <div class="add_update_role">
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="dialogForm" :rules="rules" :disabled="isCheck" :label-width="formLabelWidth">
                    <!-- <el-form-item label="场站编号:" prop="xianlu">
							<el-input v-model="diaform.xianlu" :disabled="true"></el-input>
					</el-form-item>
					<el-form-item label="场站名称:" prop="xianname">
							<el-input v-model="diaform.xianname" :disabled="true"></el-input>
					</el-form-item>-->
                    <el-form-item label="场站组织:" prop="stationName">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.stationName"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="车牌号:" prop="ids">
                        <div>
                            <el-row>
                                <el-col :span="20">
                                    <el-select v-model="selectedIdsList" multiple placeholder="" disabled class="select_view">
                                        <el-option disabled v-for="item in selectedList" :key="item.vehicleId" :label="item.vehicleNo" :value="item.vehicleId">
                                            <span v-text="item.name||''"></span>
                                            <span v-text="item.order||''"></span>
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="4" class="button_sites">
                                    <el-button type="primary" size="small" @click="siteSelect" v-if="!isCheck">设置</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark">
                        <el-input v-model="diaform.remark" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="!isCheck">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="save('dialogForm')">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
        <depotcar-select ref="siteSelectDialog" @updateSite="onSite"></depotcar-select>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" selectType="2" url="/stationRelVehicle/getStationTree" title="选择场站组织" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import depotcarSelect from '@/components/pages/admin/resourcesAllocation/depotCar/depotcarSelect'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            type: '',
            isCheck: false,
            title: '',
            siteTypeList: [],
            updowmnDisabled: false,
            upDownList: [
                {
                    value: 0,
                    label: '上行'
                },
                {
                    value: 1,
                    label: '下行'
                }
            ],
            zdTypeList: [],
            props: {
                children: 'children',
                label: 'name'
            },
            expandedKeys: [],
            zzstatusList: [
                {
                    label: '快速充电站',
                    value: 1
                },
                {
                    label: '一般充电站',
                    value: 2
                }
            ],
            diaform: {
                stationId: '',
                stationName: '',
                ids: '',
                remark: '',
                vin: ''
            },
            rules: {
                stationName: [
                    {
                        required: true,
                        message: '请选取场站组织',
                        trigger: ['change']
                    }
                ],
                ids: [
                    {
                        required: true,
                        message: '请选取车辆',
                        trigger: ['change']
                    }
                ]
            },
            dialogVisible: false,
            formLabelWidth: '100px',
            selectedList: [],
            selectedIdsList: []
        }
    },
    components: {
        depotcarSelect,
        institutionsTree
    },
    activated() {
        this.getsiteTypeList()
    },
    created() {},
    watch: {
        type: function(val) {
            let t = ''
            let isCheck = false
            switch (val) {
                case 'add':
                    t = '新增车辆分配'
                    break
                case 'update':
                    t = '修改车辆分配'
                    break
                case 'view':
                    t = '查看车辆分配'
                    isCheck = true
                    break
                default:
                    t = '新增车辆分配'
            }
            this.isCheck = isCheck
            this.title = t
        },
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('dialogForm')
                this.diaform = {
                    stationId: '',
                    stationName: '',
                    ids: '',
                    remark: '',
                    vin: ''
                }
                this.selectedIdsList = []
                this.selectedList = []
            }
        },
        selectedIdsList: function(val) {
            this.diaform.ids = val.map(one => one)
        }
    },
    methods: {
        // 组织
        selectInstitutions() {
            if (!this.isCheck) {
                this.$refs.institutions.init()
            }
        },
        addInstitutions(data) {
            this.diaform.stationId = data.stationId
            this.diaform.stationName = data.name
        },
        // 线路类别
        getsiteTypeList() {
            //   let url = '/system/org/queryOrgTree'
            //   this.$http.post(url).then(({ detail }) => {
            //     console.log(detail)
            //     this.siteTypeList = detail
            //   })
            this.siteTypeList = [
                {
                    value: 1,
                    label: '上下行'
                },
                {
                    value: 2,
                    label: '环形'
                }
            ]
        },
        init(option, type) {
            this.type = type ? type : 'add'
            this.dialogVisible = true
            if (option) {
                this.$nextTick(() => {
                    if (this.type == 'add') {
                        let addOption = JSON.parse(JSON.stringify(option))
                        this.diaform.stationId = addOption.stationId
                        this.diaform.stationName = addOption.stationName
                    } else {
                        let updateOption = JSON.parse(JSON.stringify(option))
                        this.diaform = updateOption
                        if (updateOption.state == '启用') {
                            this.diaform.state = 1
                        } else if (updateOption.state == '禁用') {
                            this.diaform.state = 0
                        }
                        this.selectedList = option.vehicles
                        this.setSelectIdsList()
                    }
                })
            }
        },
        setSelectIdsList(list) {
            list = list || this.selectedList
            this.selectedIdsList = list.map(one => {
                return one.vehicleId
            })
        },
        // 车辆选择
        siteSelect() {
            this.$refs.siteSelectDialog.init(
                this.selectedIdsList,
                this.selectedList
            )
        },
        onSite(selectedIdsList, selectedList) {
            this.selectedList = selectedList
            this.selectedIdsList = selectedIdsList
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let url = '/stationRelVehicle/'
                    if (this.type == 'add') {
                        url = url + 'add'
                    } else {
                        url = url + 'modif'
                    }
                    let option = {
                        ...this.diaform
                    }
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style type="text/css" lang="scss" scoped="add_update_role">
.select_view {
    width: 100%;
}

.button_sites {
    text-align: right;
}
</style>
